<template>
    <v-card>
        <v-card-title>Add Zone</v-card-title>
        <v-card-text class="pb-0 pt-2">
            <v-text-field outlined label="Name" v-model="zone.name" />
        </v-card-text>
        <v-card-actions>
            <v-btn block large @click="save" class="primary" :disabled="processing" :loading="processing">Save</v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
export default {
	name: 'ZoneForm',
	props: {
        location: {
            type: Object,
            required: true,
            default: null
        }
    },
    data() {
        return {
            zone: {
                name: null
            },
            processing: false
        }
    },
    methods: {
        save() {
            this.processing = true
            this.$store.dispatch('createZone', this.zone).then(() => {
                this.$store.commit('updateActionSuccess', {message: 'Zone Created', subMessage: 'You may need to refresh to reflect the changes.'})
								this.$store.commit('updateGenericDialog', null)
            }).finally(() => {
                this.processing = true
            })
        }
    }
}
</script>
